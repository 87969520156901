<template>
  <div class="login">
      <LoginHeader />
      <div class="conten">
          <div class="loginconten">
           <div class="LoginBox">
              <div class="logintype">账号登录</div>
              <div class="account" :class="Keyboardcolor==1?'focus':''"> <i class="iconfont  icon_yonghu"></i>
               <input ref="input1" @focus="Getfocus(1, $event)"  v-model="account" placeholder="请输入账号"> 
                <div class="iconfont iconjianpan"    @click="clickKeyboard(1, $event)"></div>
               </div>
                <div class="password" :class="Keyboardcolor==2?'focus':''"><i class="iconfont  icon_mima"></i> 
               <input  ref="input2" @focus="Getfocus(2, $event)" type="password" v-model="password" placeholder="请输入密码">
               <div class="iconfont iconjianpan"    @click="clickKeyboard(2, $event)"></div>
               </div>
              <div class="Verification" :class="Keyboardcolor==3?'focus':''"> 
               <div>
                <i class="iconfont  iconyanzheng"></i>
                <input type="text"  ref="input3"  @focus="Getfocus(3, $event)" v-model="VerificationCode" placeholder="请输入验证码">
             
                </div>
             
<img @click="getlogincode" :src="VerificationCodeSrc" alt="">
               <div class="iconfont iconjianpan"    @click="clickKeyboard(3, $event)"></div>
           
               
               </div>
              <div class="immediately" @click="login">立即登录</div>
           </div>
           </div>
      </div>
      <Loginfooter/>
    <touch-keyboard
      v-if="keyboardShow"
      :warn-message="keyboardMessage"
      layout="normal"
      @data-change="getPhoneNum"
    ></touch-keyboard>
  </div>
</template>

<script>
import { resEncrypt } from '@/utils/index.js'
import LoginHeader from '@/components/loginHeder'
import Loginfooter from '@/components/Loginfooter'
import touchKeyboard from '@/components/keyboard/index'
export default {
components:{
    LoginHeader,Loginfooter,touchKeyboard
},
data(){
return {
        keyboardShow: false,
              keyboardMessage: '',
              Keyboardcolor:'',
account:'',
password:'',
VerificationCodeSrc:'',
VerificationCode:''
}
},
created(){
 this.getlogincode()
 document.onkeydown=(ev)=>{
                var event=ev ||event
                if(event.keyCode==13){
                   this.login()
                }
            }
},
methods:{
 //键盘隐藏显示
    getPhoneNum(value, r) {
      console.log(3)
      this.keyboardShow = value
    },
    // 选择输入框键盘
    Getfocus(r, j) {
      console.log(2)
      this.Keyboardcolor = r
      this.keyboardMessage = j.srcElement
    },
    clickKeyboard(r, e) {
     setTimeout(() => {
           this.$refs['input'+r].focus()
     }, 100);
   
      this.Keyboardcolor = r
      this.keyboardMessage = e.currentTarget.previousElementSibling.firstElementChild
      if (!this.keyboardShow) this.keyboardShow = true
    },
  // 获取登录验证码
    getlogincode() {
        this.axiosPost('/api/user/v50/login/app/getlogincode',{ time: new Date().getTime()},(res=>{
        this.VerificationCodeSrc = res.obj
}))
    
    },
 login(){
 if(!this.account) return   this.$message.error('请输入账号');
 if(!this.password) return   this.$message.error('请输入密码');
  if(!this.VerificationCode) return   this.$message.error('请输入验证码');
 let passwordRsa = resEncrypt(this.password, this.resKey)
 
      let paramJson = {
        loginName: this.account,
        loginPwd: passwordRsa,
        appSource: 2,
        appId: '3331e2a2b2a511e982d0525400c04357',
        deviceId: '',
        phoneModel: '',
        phonePlatform: 'windows',
        code:this.VerificationCode
      }
this.axiosPost('/api/user/v50/login/app/login',paramJson,(res=>{
    if(res.code==0){
        localStorage.setItem('token',res.obj)
        if(this.$route.query.father)
          this.$router.push(this.$route.query.father)
        else
          this.$router.push('/home')
    }else{
      if(res.code=="7010"){

        
        this.$message.error('该账号密码错误超过5次，将被锁定5分钟禁止登录');
      }
      else{
        this.$message.error(res.msg);
      }
         this.getlogincode()
    }

}))
    },

}
}
</script>
<style scoped lang="less">
.focus{
    border: 1px solid #0735a4 !important;
     >.iconfont{
        color: #0735a4
    }
}
.iconjianpan{
    width: .3rem;
    font-size: 20px;
    cursor: pointer;
}
.immediately{
width: 3.48rem;
height: .46rem;
background: #0735a4;
border-radius: .23rem;
color: #ffffff;
margin: auto;
line-height: .46rem;
cursor: pointer;

}
input{
      border: none;
    outline:none;
}
input::-webkit-outer-spin-button,

input::-webkit-inner-spin-button { -webkit-appearance: none; }

input[type="number"]{ -moz-appearance: textfield; }

.login{
    height: 100%;
    // display: flex;
    // flex-direction: column;
    overflow-y: auto;
        // overflow: hidden;
    // margin-top: 70px;
    &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(150, 150, 150, 0.66);
            border: 4px solid rgba(150, 150, 150, 0.66);
            border-radius: 5px;
            background-clip: content-box;
          }
    .Verification{
        box-sizing:content-box;
        >img{
                width: 1.2rem;
    height: 100%;
    cursor: pointer;
        }
       
        >div{
            height: 100%;
            flex: 1;
            display: flex;
            align-items: center;
             >i{
    margin: 0 .1rem;
}
            >input{
    flex: 1;
    height: 90%;
  
}
        }
    }
    .account,.password,.Verification{
width: 3.51rem;
height: .45rem;
margin: auto;
border: 1px solid #ccc;
margin-bottom:.41rem ;
border-radius: .05rem;
display: flex;
align-items: center;
>i{
    margin: 0 .1rem;
}
>input{
    flex: 1;
    height: 90%;
  
}
    }
    .loginconten{
width: 12rem;
    background: url('../../assets/login/back.png') no-repeat;
      background-position: center;
      height: 100%;
      // min-height: 500px;
      margin: auto;
    }
    .conten{
    width: 100%;
    background: #EBEDEF;
      text-align: center;
        position: relative;
        flex: 1;
        height: 700px;
            margin-top: 80px;

.logintype{
    font-size: .20rem;
font-family: Microsoft YaHei, Microsoft YaHei-Bold;
font-weight: 700;
margin: .37rem 0 .2rem 0;
color: #072a80;
}
.LoginBox{
    overflow: hidden;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%,-50%);
  width: 4.40rem;
height: 4.2rem;  
background: #ffffff;
box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16); 
border-radius: 5px;
}
    }
  }
</style>