<template>
  <div class="softKeyboard">
    <vue-touch-keyboard
      :options="options"
      :layout="layout"
      :cancel="hide"
      :accept="accept"
      :next="next"
      :change="change"
      :input="input"
    />
  </div>
</template>

<script>
export default {
  props: {
    warnMessage: { type: null, default: null }, //错误提示
    layout: { type: String, default: 'normal' }, //键盘的种类：normal:全键盘/numeric：数字/compact：字母
  },
  data() {
    return {
      input: this.warnMessage,
      options: {
        useKbEvents: false,
        preventClickEvent: false,
      },
    }
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    warnMessage(newV, oldV) {
      this.input = newV
    },
  },
  methods: {
    accept(text) {
      this.hide(text)
    },
    hide() {
      this.$emit('data-change', false)
    },
    next() {
      this.$message({
        message: '无效按键',
        type: 'warning',
      })
    },
    //回车键
    change() {
      // this.$emit('data-change', false)
    },
  },
  components: {},
}
</script>

<style scoped lang="less">
.softKeyboard {
  position: fixed;
  right: 20px;
  bottom:30px;
  z-index: 999;
  width: 700px;
  padding: 5px;
  background: #333;
  border-radius: 5px;


}
</style>
